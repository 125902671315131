/**
 * Client API class for online enrollment
 */

const assert = require("assert");
const Validator = require("jsonschema").Validator;
const postEmailCaseSchema = require("./schema/postEmailCase.json");
const postCaseSchema = require("./schema/postCase.json");
const postContactSchema = require("./schema/postContact.json");
const postLeadSchema = require("./schema/postLead.json");
const postApplicationSchema = require("./schema/postApplication.json");
const patchApplicationSchema = require("./schema/patchApplication.json");

const defaultHeaders = {
  //"Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY
  Accept: "application/json"
};
function capitalize(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
function toCamelKeys(m) {
  return Array.isArray(m)
    ? m.map((i) => toCamelKeys(i))
    : typeof m === "object"
    ? Object.entries(m).reduce((a, [k, v]) => {
        a[k[0].toLowerCase() + k.slice(1)] = toCamelKeys(v);
        return a;
      }, {})
    : m;
}

export class UpApi {
  constructor(config = {}) {
    this.config = {};
    this.configure({
      ...{
        baseUri: null,
        accessToken: null,
        headers: {},
        scaffold: {
          get: null // function to custom map uri for GET , return falsey to just use baseUri+uri
        }
      },
      ...config
    });
    this.validator = new Validator();
  }

  configure(config) {
    config && Object.assign(this.config, config);
    return this.config;
  }
  checkConfigured() {
    assert(this.config && this.config.baseUri, "No baseUri - please configure");
  }
  async getAccessToken() {
    const rv = typeof this.config.accessToken === "function" ? this.config.accessToken() : this.config.accessToken;
    const token = await rv;
    return token;
  }

  toError(res, message, requestBody = {}) {
    if (res instanceof Error) return {message: `${res.message || "Error"}: ${message || "Network problem"}`};
    else
      return {
        message: `${res.statusText || "Error"}: ${message || "Network problem"}`,
        status: res.status,
        statusCode: res.status,
        requestBody
      };
  }

  async getAuthorization() {
    return {
      Authorization: "Bearer " + (await this.getAccessToken())
    };
  }

  async withAuthorization(uri) {
    // If uri has /xx/secure/ prefix, include Authorization headers
    return /^.*\/secure\//.test(uri) ? await this.getAuthorization() : {};
  }

  /**
   * Raw GET
   * @param {*} uriRaw
   * @param {*} extraHeaders
   */
  async get(uriRaw, extraHeaders = {}) {
    const uri = encodeURI(uriRaw);
    this.checkConfigured();
    const message = `Up API GET ${uri}`;
    const options = {
      headers: {
        ...defaultHeaders,
        ...this.config.headers,
        ...(await this.withAuthorization(uri)),
        ...extraHeaders
      }
    };
    const {
      baseUri,
      scaffold: {get: scaffoldGet}
    } = this.config;
    return fetch((scaffoldGet && scaffoldGet(uri)) || baseUri + uri, options).then(
      (res) => {
        if (res.ok)
          return res.json().then((js) => {
            // Api sometimes returns success even when it has failed, with error code in the payload
            // check various error objects
            // these aren't specified anywhere AFAIK but found during testing
            const bad = "Bad response from API (marked as success)";
            if (js.error) {
              // { error: { code: XXX, message: "YYY"}}
              throw this.toError({status: js.error.code, statusText: bad}, js.error.message);
            } else if (js.errorCode) {
              // { errorCode: XXX, errorMessage: "YYY"}}
              throw this.toError({status: js.errorCode, statusText: bad}, js.errorMessage);
            } else if (js.Response === "An action failed. No dependent actions succeeded.") {
              // Another crazy response, no status code, no error message just a 'Response' with cryptic message, seen often so caught here
              throw this.toError({statusText: bad}, js.Response);
            } else {
              // Success
              return js;
            }
          });
        else throw this.toError(res, message);
      },
      (error) => {
        throw this.toError(error, message);
      }
    );
  }
  /**
   * Raw POST
   * @param {*} uri
   * @param {*} params
   * @param {*} extraHeaders
   */
  async post(uri, params = {}, extraHeaders = {}) {
    this.checkConfigured();
    const message = `Up API POST ${uri}`;
    return fetch(this.config.baseUri + uri, {
      headers: {
        "Content-Type": "application/json",
        ...defaultHeaders,
        ...this.config.headers,
        ...(await this.withAuthorization(uri)),
        ...extraHeaders
      },
      method: "POST",
      body: JSON.stringify(params)
    }).then(
      (res) => {
        if (res.ok) {
          return (res.headers.get("Content-Type") || "").includes("application/json") ? res.json() : res;
        } else {
          throw this.toError(res, message, params);
        }
      },
      (error) => {
        throw this.toError(error, message, params);
      }
    );
  }
  /**
   * Raw PUT
   * @param {*} uri
   * @param {*} params
   * @param {*} extraHeaders
   */
  async put(uri, params = {}, extraHeaders = {}) {
    this.checkConfigured();
    const message = `Up API PUT ${uri}`;
    return fetch(this.config.baseUri + uri, {
      headers: {
        "Content-Type": "application/json",
        ...defaultHeaders,
        ...this.config.headers,
        ...(await this.withAuthorization(uri)),
        ...extraHeaders
      },
      method: "PUT",
      body: extraHeaders["Content-Type"] ? params : JSON.stringify(params)
    }).then(
      (res) => {
        if (res.ok) {
          return (res.headers.get("Content-Type") || "").includes("application/json") ? res.json() : res;
        } else {
          throw this.toError(res, message, params);
        }
      },
      (error) => {
        throw this.toError(error, message, params);
      }
    );
  }
  /**
   * Raw PATCH
   * @param {*} uri
   * @param {*} params
   * @param {*} extraHeaders
   */
  async patch(uri, params = {}, extraHeaders = {}) {
    this.checkConfigured();
    const message = `Up API PATCH ${uri}`;
    return fetch(this.config.baseUri + uri, {
      headers: {
        "Content-Type": "application/json",
        ...defaultHeaders,
        ...this.config.headers,
        ...(await this.withAuthorization(uri)),
        ...extraHeaders
      },
      method: "PATCH",
      body: JSON.stringify(params)
    }).then(
      (res) => {
        if (res.ok) {
          return (res.headers.get("Content-Type") || "").includes("application/json") ? res.json() : res;
        } else {
          throw this.toError(res, message, params);
        }
      },
      (error) => {
        throw this.toError(error, message, params);
      }
    );
  }

  validate(body, schema) {
    const validation = this.validator.validate(body, schema);
    if (!validation.valid) {
      console.error(validation.toString(), body);
      throw new Error("Application data validation error");
    }
  }

  async activity() {
    return this.get("v1/activity");
  }

  async createApplication(body) {
    this.validate(body, postApplicationSchema);
    return this.post("v2/studentApplication", body);
  }

  async debitSuccessNotification(salesJourneyCRMId, accountId) {
    return this.post(`v1/debitSuccess/notification/${salesJourneyCRMId}/${accountId}`);
  }

  async updateApplication(opportunityId, body) {
    this.validate(body, patchApplicationSchema);
    return this.patch(`v2/secure/studentApplication/${opportunityId}`, body);
  }
  async application(opportunityId) {
    return this.get(`v2/secure/studentApplication/${opportunityId}`);
  }

  async contact(contactId) {
    return this.get("v1/contact/" + contactId);
  }

  async createCase(body) {
    this.validate(body, postCaseSchema);
    return this.post("v1/secure/case", body);
  }

  async createEmailCase(body) {
    this.validate(body, postEmailCaseSchema);
    return this.post("v1/secure/emailsupportcase", body);
  }

  async createContact(body) {
    this.validate(body, postContactSchema);
    return this.post("v1/contact", body);
  }
  async createTask(body) {
    return this.post("v1/secure/task", body);
  }

  async updateContact(body) {
    this.validate(body, putContactSchema);
    return this.put("v1/contact", body);
  }

  async country() {
    return this.get("v1/country");
  }
  async course(courseId) {
    return this.get(`v2/course?courseid=${courseId}`);
  }
  async courses(provider) {
    return this.get(`v2/product?provider=${encodeURIComponent(provider)}`); // TODO: I expect this will change to param per provider ;-)
  }

  async employers({top = 50, q = ""}) {
    return this.get(`v1/employers?top=${top}&q=${q}`);
  }

  async ethnicity() {
    return this.get("v1/ethnicity");
  }

  /**
   * This is a temporary cludge to do the cognito creation on the backend createApplication will do this
   * @param {*} studentId
   * @param {*} email
   */
  async createIdentity(studentId, email) {
    return this.post("v1/identity", {username: studentId, email: email});
  }

  /**
   * Trigger send of a passcode
   * @param {*} provider
   * @param {*} studentId
   * @returns Promise resolving to error 404 if provider/student tuple not found or 200 (no data)
   */
  async createPasscodeSession(provider, studentId) {
    return this.post(`v1/session/passcode/${provider}/${studentId}`);
  }

  /**
   * Check and fetch session for given passcode
   * @param {*} provider
   * @param {*} studentId
   * @returns Promise resolving to 404 if student/passcode not found/invalid or 200 and
   */
  async passcodeSession(studentId, passcode) {
    return this.get(`v1/session/passcode/${studentId}/${passcode}`);
  }

  async industryCategory() {
    return this.get("v1/job/industryCategory");
  }

  async intake() {
    return this.get("v1/intake");
  }

  async iwi() {
    return this.get("v1/iwi");
  }

  async createLead(body) {
    this.validate(body, postLeadSchema);
    return this.post("v1/lead", body);
  }

  async metadata() {
    return this.get("v1/metadata");
  }

  async location() {
    return this.get("v1/location");
  }

  async createOrder(opportunityId) {
    return this.post(`v2/secure/studentApplication/${opportunityId}/order`, {});
  }

  async createDebitSuccessAccount(body) {
    return this.post(`v1/secure/debitSuccess`, body);
  }

  async createEmployer(body) {
    return this.post(`v1/secure/createemployer`, body);
  }

  async createStripeSession(opportunityId, params) {
    return this.post(`v1/secure/studentApplication/${opportunityId}/stripeSession`, params);
  }

  async createAdhocStripeSession(studentId, params) {
    return this.post(`v1/student/${studentId}/stripeSession`, params);
  }

  async feesAssistanceStatus() {
    return this.get("v1/feesAssistanceStatus");
  }

  async qualification() {
    return this.get("v1/qualification");
  }
  async relationship() {
    return this.get("v1/relationship");
  }
  async school() {
    return this.get("v1/school");
  }
  async sponsor() {
    return this.get("v1/sponsor");
  }

  async enrolments(studentId, enrolmentId = null) {
    return this.get(
      `v1/secure/enrolment?studentupid=${studentId}` + (enrolmentId ? `&enrolmentid=${enrolmentId}` : ""),
      await this.getAuthorization()
    );
  }

  async feesFreeEligibility(nsi) {
    return this.get(`v1/feesFree/${nsi}/eligibility`);
  }

  async createGraduateDestination(body) {
    return this.post("v1/secure/graduateDestination", body);
  }
  async graduates(options) {
    return this.get(
      `v1/secure/graduateDestination/graduates?${Object.entries(options)
        .map(([k, v]) => `${k}=${v}`)
        .join("&")}`
    );
  }

  async payments(studentId) {
    return this.get(`v1/secure/payment?studentupid=${studentId}`).then((v) => toCamelKeys(v)); // fix json to camelcased keys - some .NET issue in the back end
  }

  async applicationPricing(opportunityId, provider) {
    return this.get(`v2/secure/application/${opportunityId}/pricing${provider ? `?provider=${provider}` : ""}`);
  }

  async productPricing(productId, provider) {
    return this.get(`v2/product/${productId}/pricing${provider ? `?provider=${provider}` : ""}`);
  }
  async invoicePricing(invoiceId) {
    return this.get(`v2/secure/invoice/${invoiceId}/pricing`);
  }

  async session(sessionId) {
    return this.get(`v1/session/${sessionId}`);
  }

  async student(studentId) {
    const url = "v1/secure/student";
    return this.get(studentId ? `${url}?studentupid=${studentId}` : url);
  }
  async studentFiles(studentId) {
    return this.get(`v1/secure/student/${studentId}/file`);
  }
  async studentFile(studentId, fileId) {
    return fetch(`${this.config.baseUri}v1/secure/student/${studentId}/file?fileid=${fileId}`, {
      headers: await this.getAuthorization()
    }).then((response) => {
      if (response.ok) return response.blob();
      else throw this.toError(response, "GET file");
    });
  }

  async studentImage(studentId) {
    return fetch(`${this.config.baseUri}v1/secure/student/image?studentupid=${studentId}`, {
      headers: await this.getAuthorization()
    }).then((response) => {
      if (response.ok) return response.blob();
      else throw this.toError(response, "GET image");
    });
  }

  async updateStudentImage(studentId, blob) {
    const url = "v1/secure/student/image";
    return this.put(studentId ? `${url}?studentupid=${studentId}` : url, blob, {
      ...(await this.getAuthorization()),
      "Content-Type": "application/octet-stream"
    });
  }

  async updateStudent(studentProfile) {
    return this.patch("v1/secure/student", studentProfile);
  }

  async updateAdAccountPassword(studentId, newPassword) {
    return this.put("v1/secure/adaccountpassword", {
      password: newPassword,
      username: studentId
    });
  }

  async supportingDocuments(files, opportunityId) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file, file.name);
    });
    formData.set("opportunityid", opportunityId);

    return fetch(this.config.baseUri + "v1/files", {
      method: "POST",
      body: formData
    }).then((response) => response.json());
  }

  async vacancy(businessUnit) {
    return this.get("job/vacancy?businessUnit=" + capitalize(businessUnit));
  }

  async bulkStudentApplication(body) {
    return this.post("v1/bulkStudentApplication", body);
  }

  async getWorkplaceEducators() {
    return this.get("v1/workplaceEducators");
  }

  async getWorkplaceEmployers(accountId) {
    return this.get(`v1/workplace?accountType=${accountId}`);
  }

  async createWorkplaceEmployer(body) {
    return this.post("v1/secure/workplace", body);
  }

  async createZipCheckout(opportunityId, body) {
    return this.post(`v1/secure/studentApplication/${opportunityId}/zipCheckout`, body);
  }

  async createZipCharge(opportunityId, body) {
    return this.post("v1/secure/zip/charge", body, {"opportunityId": opportunityId});
  }
}

// Singleton - must be configured
const theApi = new UpApi({});
export default theApi;
